import { onCleanup, onMount } from "solid-js";
import * as rive from "@rive-app/canvas";

declare namespace Rive {
  type Props = {
    src: string;
    class?: string;
  };
}

function Rive({ src, class: className }: D<Rive.Props>) {
  let ref: HTMLCanvasElement;

  onMount(() => {
    const r = new rive.Rive({
      src,
      canvas: ref,
      autoplay: true,
      onLoad() {
        r.resizeDrawingSurfaceToCanvas();
      },
    });
    onCleanup(() => {
      try {
        r.cleanup();
      } catch (e) {
        // Do nothing, .cleanup can throw without concern
      }
    });
  });

  return <canvas ref={ref!} class={className} />;
}

export default Rive;
